<template>
  <div
    v-if="_errors.length"
    class="p-1 rounded bg-red-100 border border-red-300"
  >
    <small>
      <ul class="px-5 text-red-500 list-disc">
        <li v-for="(err, index) in _errors" :key="`field-error-${index}`">
          {{ err }}
        </li>
      </ul>
    </small>
  </div>
</template>

<script setup lang="ts">
import { useApiErrorsStore } from '~~/store/api_errors'

const props = withDefaults(
  defineProps<{ apiKey: string; except?: string[]; withMessage?: boolean }>(),
  { apiKey: 'error', except: () => [], withMessage: true }
)

const { getApiError } = useApiErrorsStore()

const _errors = computed(() => {
  return getApiError(props.apiKey, props.except, props.withMessage)
})
</script>
